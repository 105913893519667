/*
 * Copyright(c) 2021 eCert, All Rights Reserved.
 * Author: lxj
 */

import axios from 'axios';
import {
  baseUrl
} from '@/config/env'

// 创建axios实例
const fetch = axios.create({
  baseURL: baseUrl,
  timeout: 15000 // 请求超时时间
});

export default {
  // 查询获奖证书
  getCertificateUrl: params => {
    return fetch({
      url: '/competition-certificate/export',
      method: 'get',
      params
    })
  }
}